import React from 'react';
import { Typography, Grid, Card, CardContent, Box, Container, Button } from '@mui/material';
import { AutoAwesome, Speed, Lock, EmojiEmotions } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

// No need to import the image since it's in the public folder
// Simply use the public URL to reference the image

function Features() {
  const features = [
    { title: '"AI"-Powered', description: 'Avrage CS memes using overhyped "AI"', icon: <AutoAwesome /> },
    { title: 'Fast enough', description: 'Get your dank CS memes in seconds, not minutes', icon: <Speed /> },
    { title: 'Secure & Private ish', description: 'Your memes and data are always "safe" with us', icon: <Lock /> },
    { title: 'Three FREE Memes', description: 'OMG! New users get three FREE memes', icon: <EmojiEmotions /> },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
      {/* Image Section */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <img src={`${process.env.PUBLIC_URL}/pepe_5.png`} alt="Pepe" style={{ maxWidth: '33%', height: 'auto' }} />
      </Box>

      {/* Title Section */}
      <Typography variant="h2" component="h1" gutterBottom align="center" sx={{ 
        color: '#2F9D00', 
        fontWeight: 'bold',
        textShadow: '2px 2px #000',
        textTransform: 'uppercase',
      }}>
        Features
      </Typography>
      <Typography variant="h5" align="center" color="textSecondary" paragraph sx={{
        textShadow: '1px 1px #000',
      }}>
        Discover what makes PepeAI unique
      </Typography>

      {/* Features Grid */}
      <Grid container spacing={4} sx={{ mt: 4 }}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card raised sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              backgroundColor: 'rgba(47, 157, 0, 0.1)', 
              border: '2px solid #2F9D00',
              borderRadius: '10px',
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}>
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  {React.cloneElement(feature.icon, { fontSize: 'large', sx: { color: '#FF4500' } })}
                </Box>
                <Typography variant="h5" component="h2" gutterBottom align="center" sx={{ 
                  color: '#2F9D00',
                  textShadow: '1px 1px #000',
                  textTransform: 'uppercase',
                }}>
                  {feature.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center" sx={{
                  textShadow: '0.5px 0.5px #000',
                }}>
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Call to Action Section */}
      <Box sx={{ mt: 6, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom sx={{ 
          color: '#2F9D00',
          textShadow: '1px 1px #000',
        }}>
          Ready to create your first CS meme?
        </Typography>
        <Typography variant="body1" paragraph sx={{
          textShadow: '0.5px 0.5px #000',
        }}>
          Sign up now and get 3 meme generations for free!
        </Typography>
        <Button
          variant="contained"
          size="large"
          component={RouterLink}
          to="/login"
          sx={{ 
            mt: 2, 
            backgroundColor: '#FF4500',
            '&:hover': {
              backgroundColor: '#FF6A33',
            },
            textShadow: '1px 1px #000',
            fontWeight: 'bold',
          }}
        >
          Get Started
        </Button>
      </Box>
    </Container>
  );
}

export default Features;
