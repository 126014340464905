import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  Snackbar,
  Alert,
  IconButton,
  useTheme,
  LinearProgress,
} from '@mui/material';
import { CloudUpload, PauseCircle, PlayCircle, Refresh, ShoppingCart } from '@mui/icons-material';
import { auth, firestore, functions } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useNavigate, useLocation } from 'react-router-dom';

function Dashboard() {
  const [user, setUser] = useState(null);
  const [credits, setCredits] = useState(0);
  const [image, setImage] = useState(null);
  const [meme, setMeme] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const theme = useTheme();

  // Fetch user data
  const fetchUserData = async (userId) => {
    try {
      const getUserDataFunc = httpsCallable(functions, 'getUserData');
      const result = await getUserDataFunc();
      setCredits(result.data.credits || 0);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to fetch user data. Please try again later.');
    }
  };

  // Fetch user data when component mounts
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        await fetchUserData(currentUser.uid);
      } else {
        setUser(null);
        setCredits(0);
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Handle successful payment by fetching updated user data
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get('session_id')) {
      fetchUserData(user?.uid);
    }
  }, [location.search, user]);

  // Set video volume
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = 0.20; // Set volume to 20%
    }
  }, []);

  const simulateProgress = () => {
    setProgress(0);
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + Math.random() * 10;
        return newProgress >= 100 ? 100 : newProgress;
      });
    }, 500);
    return interval;
  };

  // Handle image upload
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    // Check file size (2.5MB = 2.5 * 1024 * 1024 bytes)
    const maxSize = 2.5 * 1024 * 1024; // 2.5MB in bytes
    if (file.size > maxSize) {
      setError('File size exceeds 2.5MB limit. Please choose a smaller file.');
      return;
    }
  
    // Check file type
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    if (!allowedTypes.includes(file.type)) {
      setError('Invalid file type. Please upload a JPEG, PNG, GIF, or WebP image.');
      return;
    }
  
    setLoading(true);
    setError(null);
    try {
      const base64File = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
      });
  
      const uploadImageFunc = httpsCallable(functions, 'uploadImage');
      const result = await uploadImageFunc({
        file: base64File,
        contentType: file.type
      });
  
      if (!result.data || !result.data.imageUrl) {
        throw new Error('Failed to upload image');
      }
  
      const { filename } = result.data;
      setImage(filename);
  
      const reader = new FileReader();
      reader.onloadend = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
  
      setSuccess('Image uploaded successfully!');
    } catch (error) {
      console.error('Error uploading image:', error);
      setError(`Failed to upload image: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Generate meme
  const generateMeme = async () => {
    if (!image || credits <= 0) {
      setError('Please upload an image and ensure you have enough credits.');
      return;
    }

    setLoading(true);
    setError(null);
    const progressInterval = simulateProgress();

    try {
      const generateMemeCaptionFunc = httpsCallable(functions, 'generateMemeCaption');
      const result = await generateMemeCaptionFunc({ imageUrl: image });

      const { caption, imageUrl } = result.data;

      setMeme({ imageUrl, caption });

      const newCredits = credits - 1;
      setCredits(newCredits);
      await updateDoc(doc(firestore, 'users', user.uid), { credits: newCredits });

      setSuccess('Meme generated successfully!');
    } catch (error) {
      console.error('Error generating meme:', error);
      setError('Failed to generate meme. Please try again later.');
    } finally {
      clearInterval(progressInterval);
      setProgress(100);
      setTimeout(() => {
        setLoading(false);
        setProgress(0);
      }, 500);
    }
  };

  // Regenerate meme
  const regenerateMeme = async () => {
    if (!image || credits <= 0) {
      setError('Please upload an image and ensure you have enough credits.');
      return;
    }

    setLoading(true);
    setError(null);
    const progressInterval = simulateProgress();

    try {
      const regenerateMemeFunc = httpsCallable(functions, 'regenerateMeme');
      const result = await regenerateMemeFunc({ imageUrl: image });

      const { caption, imageUrl } = result.data;

      setMeme({ imageUrl, caption });

      const newCredits = credits - 1;
      setCredits(newCredits);
      await updateDoc(doc(firestore, 'users', user.uid), { credits: newCredits });

      setSuccess('Meme regenerated successfully!');
    } catch (error) {
      console.error('Error regenerating meme:', error);
      setError('Failed to regenerate meme. Please try again later.');
    } finally {
      clearInterval(progressInterval);
      setProgress(100);
      setTimeout(() => {
        setLoading(false);
        setProgress(0);
      }, 500);
    }
  };

  // Toggle video play/pause
  const toggleVideo = () => {
    if (videoRef.current) {
      if (isVideoPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsVideoPlaying(!isVideoPlaying);
    }
  };

  // Navigate to pricing page
  const navigateToPricing = () => {
    navigate('/pricing');
  };

 const LoadingAnimation = () => (
    <Box sx={{ width: '100%', mt: 2 }}>
      <LinearProgress variant="determinate" value={progress} sx={{
        height: 10,
        borderRadius: 5,
        '& .MuiLinearProgress-bar': {
          borderRadius: 5,
          backgroundColor: theme.palette.secondary.main,
        },
      }} />
      <Typography variant="body2" color="text.secondary" sx={{ mt: 1, textAlign: 'center' }}>
        {progress < 100 ? 'Generating your meme...' : 'Almost there!'}
      </Typography>
    </Box>
  );

  return (
    <Container maxWidth="lg" sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{
            padding: 3,
            backgroundColor: theme.palette.secondary.main + '1A', // 10% opacity
            border: `2px solid ${theme.palette.secondary.main}`,
            borderRadius: '10px',
          }}>
            <Typography variant="h3" gutterBottom sx={{
              color: theme.palette.secondary.main,
              fontWeight: 'bold',
              textShadow: '2px 2px #000',
              textAlign: 'center',
            }}>
              Meme Generator
            </Typography>
            <Typography variant="h5" gutterBottom sx={{
              color: theme.palette.primary.main,
              textShadow: '1px 1px #000',
              textAlign: 'center',
            }}>
              Welcome, {user?.displayName || user?.email}!
            </Typography>
            <Box sx={{ position: 'relative', textAlign: 'center', mb: 2 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<ShoppingCart />}
                onClick={navigateToPricing}
                sx={{
                  backgroundColor: theme.palette.warning.main,
                  color: theme.palette.warning.contrastText,
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: theme.palette.warning.dark,
                  },
                  animation: 'pulse 1.5s infinite',
                  '@keyframes pulse': {
                    '0%': {
                      boxShadow: '0 0 0 0 rgba(255, 193, 7, 0.4)',
                    },
                    '70%': {
                      boxShadow: '0 0 0 10px rgba(255, 193, 7, 0)',
                    },
                    '100%': {
                      boxShadow: '0 0 0 0 rgba(255, 193, 7, 0)',
                    },
                  },
                }}
              >
                BUY CREDITS
              </Button>
            </Box>
            <Box sx={{ position: 'relative', textAlign: 'center', mb: 2 }}>
              <Typography variant="body1" gutterBottom>
                Credits: <span style={{ color: theme.palette.primary.main, fontWeight: 'bold', textShadow: '1px 1px #000' }}>{credits}</span>
              </Typography>
            </Box>
            <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'center' }}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="image-upload"
                type="file"
                onChange={handleImageUpload}
              />
              <label htmlFor="image-upload">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<CloudUpload />}
                  sx={{
                    backgroundColor: theme.palette.secondary.main,
                    '&:hover': { backgroundColor: theme.palette.secondary.dark },
                    marginRight: 2,
                    textShadow: '1px 1px #000',
                  }}
                >
                  Upload Image
                </Button>
              </label>
              <Button
                variant="contained"
                onClick={generateMeme}
                disabled={loading || credits <= 0 || !image}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': { backgroundColor: theme.palette.primary.dark },
                  '&:disabled': { backgroundColor: theme.palette.primary.main + '80' },
                  textShadow: '1px 1px #000',
                }}
              >
                Generate Meme
              </Button>
            </Box>
            {imagePreview && (
              <Box sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
                <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }} />
              </Box>
            )}
            {loading && <LoadingAnimation />}
            {meme && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h5" gutterBottom sx={{
                  color: theme.palette.secondary.main,
                  textShadow: '1px 1px #000',
                  textAlign: 'center'
                }}>
                  Generated Meme
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={meme.imageUrl} alt="Generated Meme" style={{ maxWidth: '100%', borderRadius: '8px' }} />
                </Box>
                <Typography variant="body1" sx={{
                  mt: 1,
                  color: theme.palette.primary.main,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  textShadow: '1px 1px #000',
                }}>
                  Caption: {meme.caption}
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    component="a"
                    href={meme.imageUrl}
                    download="meme.png"
                    sx={{
                      backgroundColor: theme.palette.secondary.main,
                      '&:hover': { backgroundColor: theme.palette.secondary.dark },
                      marginRight: 2,
                      textShadow: '1px 1px #000',
                    }}
                  >
                    Download Meme
                  </Button>
                  <Button
                    variant="contained"
                    onClick={regenerateMeme}
                    startIcon={<Refresh />}
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      '&:hover': { backgroundColor: theme.palette.primary.dark },
                      textShadow: '1px 1px #000',
                    }}
                  >
                    Regenerate Meme
                  </Button>
                </Box>
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src="/pepe_2.png" alt="Pepe" style={{ maxWidth: '100%', maxHeight: '400px', marginBottom: '1rem' }} />
            <Box sx={{ width: '100%', maxWidth: '400px', position: 'relative' }}>
              <video
                ref={videoRef}
                width="100%"
                height="auto"
                loop
                autoPlay
                style={{ borderRadius: '8px', border: '3px solid white' }}
              >
                <source src="/rr.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <IconButton
                onClick={toggleVideo}
                sx={{
                  position: 'absolute',
                  bottom: '8px',
                  left: '10%',
                  transform: 'translateX(-50%)',
                  color: theme.palette.secondary.main,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' }
                }}
              >
                {isVideoPlaying ? <PauseCircle /> : <PlayCircle />}
              </IconButton>
            </Box>
            <Typography variant="h3" gutterBottom sx={{
              color: theme.palette.primary.main,
              fontWeight: 'bold',
              textShadow: '1px 1px #000',
              textAlign: 'center',
              marginTop: '1rem',
            }}>
              Rick Rolled
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{
          width: '100%',
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText
        }}>
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess(null)}>
        <Alert onClose={() => setSuccess(null)} severity="success" sx={{
          width: '100%',
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText
        }}>
          {success}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Dashboard;