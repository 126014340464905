// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

let app;
let auth;
let firestore;
let functions;
let storage;

async function initializeFirebase() {
  try {
    // Fetch the Firebase configuration from your backend
    const response = await fetch('/api/getFirebaseConfig');
    if (!response.ok) {
      const text = await response.text();
      console.error('API Response:', text);
      throw new Error(`Failed to fetch Firebase configuration: ${response.statusText}`);
    }
    let firebaseConfig;
    try {
      firebaseConfig = await response.json();
    } catch (jsonError) {
      console.error('Failed to parse JSON:', jsonError);
      const text = await response.text();
      console.error('Raw API Response:', text);
      throw new Error('Invalid JSON response from server');
    }

    // Initialize Firebase with the fetched config
    app = initializeApp(firebaseConfig);
    auth = getAuth(app);
    firestore = getFirestore(app);
    functions = getFunctions(app);
    storage = getStorage(app);

    console.log('Firebase initialized successfully');
  } catch (error) {
    console.error('Error initializing Firebase:', error);
    throw error; // Re-throw the error to be handled by the caller
  }
}

// Function to ensure Firebase is initialized before use
function getFirebase() {
  if (!app) {
    throw new Error('Firebase has not been initialized. Call initializeFirebase() first.');
  }
  return { app, auth, firestore, functions, storage };
}

export { initializeFirebase, getFirebase, auth, firestore, functions, storage };