// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Import components
import Layout from './components/Layout';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Pricing from './pages/Pricing';
import Features from './pages/Features';

// Import Firebase initialization function and auth
import { initializeFirebase, auth } from './firebase';

// Create a dark theme instance with Reddit orange and Pepe Frog green accents
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FF4500', // Reddit Orange
    },
    secondary: {
      main: '#2F9D00', // Pepe Frog Green
    },
    background: {
      default: '#121212', // Very dark gray, almost black
      paper: '#1E1E1E', // Slightly lighter dark gray
    },
    text: {
      primary: '#ffffff',
      secondary: '#b0b0b0',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"Impact", "Arial Black", sans-serif',
      fontSize: '4rem',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: '"Impact", "Arial Black", sans-serif',
      fontSize: '3rem',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    h3: {
      fontFamily: '"Impact", "Arial Black", sans-serif',
      fontSize: '2.5rem',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    h4: {
      fontFamily: '"Impact", "Arial Black", sans-serif',
      fontSize: '2rem',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    h5: {
      fontFamily: '"Impact", "Arial Black", sans-serif',
      fontSize: '1.5rem',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
    h6: {
      fontFamily: '"Impact", "Arial Black", sans-serif',
      fontSize: '1.25rem',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          fontFamily: '"Impact", "Arial Black", sans-serif',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0px 2px 4px rgba(255, 69, 0, 0.25)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(18, 18, 18, 0.8)',
          backdropFilter: 'blur(8px)',
        },
      },
    },
  },
});

// Protected Route component
const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

function App() {
  const [isFirebaseInitialized, setIsFirebaseInitialized] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initFirebase = async () => {
      try {
        await initializeFirebase();
        setIsFirebaseInitialized(true);
      } catch (err) {
        console.error('Failed to initialize Firebase:', err);
        setError('Failed to initialize the application. Please try again later.');
      }
    };
    initFirebase();
  }, []);

  if (error) {
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Error
          </Typography>
          <Typography variant="body1">{error}</Typography>
        </Box>
      </ThemeProvider>
    );
  }

  if (!isFirebaseInitialized) {
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/features" element={<Features />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;