import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardActions, 
  Button, 
  Box, 
  useTheme,
  CircularProgress,
  Snackbar,
  Alert,
  Paper
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';
import { Sparkles } from 'lucide-react';

// Initialize Stripe with the publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function Pricing() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
      console.error('Stripe publishable key is not set');
      setError('Configuration error. Please contact support.');
    }
  }, []);

  const pricingOptions = [
    { credits: 10, price: '$1', priceId: 'price_1PdxR9RtxMlTuS1DcVYBxVFB', color: theme.palette.primary.main },
    { credits: 30, price: '$2.50', priceId: 'price_1Pe19WRtxMlTuS1D2QzUU326', color: theme.palette.secondary.main },
  ];

  const handlePurchase = async (option) => {
    if (!user) {
      navigate('/login');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const functions = getFunctions();
      const createStripeCheckout = httpsCallable(functions, 'createStripeCheckout');

      const { data } = await createStripeCheckout({
        priceId: option.priceId,
        credits: option.credits,
      });

      if (data && data.sessionId) {
        const stripe = await stripePromise;
        if (!stripe) {
          throw new Error('Stripe failed to initialize');
        }
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });

        if (error) {
          throw new Error(error.message);
        }
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setError(error.message || "An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 6 }}>
        <Box
          component="img"
          src="/pepe_4.png"
          alt="Pepe Meme"
          sx={{
            width: '300px',
            height: 'auto',
            mb: 4,
          }}
        />
        <Typography variant="h2" align="center" gutterBottom sx={{ 
          color: theme.palette.secondary.main, 
          fontWeight: 'bold',
          textShadow: '2px 2px #000',
          textTransform: 'uppercase',
        }}>
          Pricing
        </Typography>
        <Typography variant="h5" align="center" paragraph sx={{
          color: theme.palette.text.primary,
          textShadow: '1px 1px #000',
        }}>
          Buy your credits here kek
        </Typography>
      </Box>
      
      <Grid container spacing={4} justifyContent="center">
        {pricingOptions.map((option, index) => (
          <Grid item key={index} xs={12} sm={6} md={5}>
            <Card raised sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              backgroundColor: theme.palette.background.paper, 
              border: `2px solid ${option.color}`,
              borderRadius: theme.shape.borderRadius,
              overflow: 'hidden',
            }}>
              <CardContent sx={{ flexGrow: 1, padding: 3 }}>
                <Typography gutterBottom variant="h3" component="h2" align="center" sx={{ 
                  color: option.color,
                  textShadow: '2px 2px #000',
                  textTransform: 'uppercase',
                }}>
                  {option.credits} {option.credits === 1 ? 'Credit' : 'Credits'}
                </Typography>
                <Typography variant="h4" align="center" sx={{
                  textShadow: '1px 1px #000',
                  color: theme.palette.text.primary,
                }}>
                  {option.price}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body1" align="center" sx={{ color: theme.palette.text.primary, textShadow: '0.5px 0.5px #000' }}>
                    • Generate {option.credits} CS meme{option.credits === 1 ? '' : 's'}
                  </Typography>
                  <Typography variant="body1" align="center" sx={{ color: theme.palette.text.primary, textShadow: '0.5px 0.5px #000' }}>
                    • "AI"-powered
                  </Typography>
                  <Typography variant="body1" align="center" sx={{ color: theme.palette.text.primary, textShadow: '0.5px 0.5px #000' }}>
                    • Troll your SWE friends today
                  </Typography>
                </Box>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center', padding: 2 }}>
                <Button 
                  fullWidth 
                  variant="contained" 
                  size="large"
                  onClick={() => handlePurchase(option)}
                  disabled={loading}
                  sx={{ 
                    backgroundColor: option.color,
                    '&:hover': {
                      backgroundColor: theme.palette.mode === 'dark' 
                        ? theme.palette.augmentColor({ color: { main: option.color } }).dark 
                        : theme.palette.augmentColor({ color: { main: option.color } }).light,
                    },
                    color: theme.palette.getContrastText(option.color),
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    textTransform: 'uppercase',
                    padding: '10px 0',
                  }}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Buy Now'}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Special Offer Section */}
      <Paper elevation={3} sx={{ 
        mt: 6, 
        p: 3, 
        backgroundColor: 'rgba(47, 157, 0, 0.1)', 
        border: '2px solid #2F9D00',
        borderRadius: '10px',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
          <Sparkles size={24} color="#FF4500" />
          <Typography variant="h4" sx={{ 
            ml: 1, 
            color: '#FF4500', 
            textShadow: '1px 1px #000',
            fontWeight: 'bold',
          }}>
            Special Offer!
          </Typography>
        </Box>
        <Typography variant="h6" align="center" sx={{ color: theme.palette.text.primary, mb: 2 }}>
          New users get 3 free meme generations!
        </Typography>
        <Typography variant="body1" align="center" sx={{ color: theme.palette.text.primary }}>
          Sign up now and start creating dank memes immediately!
        </Typography>
      </Paper>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess(null)}>
        <Alert onClose={() => setSuccess(null)} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Pricing;