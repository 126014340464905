import React from 'react';
import { Typography, Button, Grid, Box, Container, useTheme, useMediaQuery } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Sparkles, ArrowRight } from 'lucide-react';

function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Custom styles with Reddit Orange and Pepe Frog green
  const styles = {
    heroSection: {
      backgroundColor: theme.palette.background.default,
      minHeight: 'calc(100vh - 64px)', // Adjust for AppBar height
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(4, 2),
    },
    pepeGreenText: {
      color: '#2F9D00',
      fontWeight: 'bold',
      textShadow: '2px 2px #000',
      textTransform: 'uppercase',
      fontSize: isMobile ? '2.5rem' : '4rem',
      textAlign: 'center',
    },
    gradientText: {
      background: `linear-gradient(45deg, #7F00FF, #E100FF)`,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontWeight: 'bold',
      textShadow: '2px 2px rgba(0, 0, 0, 0.5)',
      textTransform: 'uppercase',
      fontSize: isMobile ? '1.8rem' : '3rem',
      textAlign: 'center',
    },
    subText: {
      color: '#FFFFFF',
      textShadow: '1px 1px rgba(0, 0, 0, 0.5)',
      textAlign: 'center',
      fontSize: isMobile ? '1rem' : '1.25rem',
    },
    freeTrialBox: {
      backgroundColor: 'rgba(47, 157, 0, 0.1)', // Pepe green with opacity
      borderRadius: '10px',
      padding: theme.spacing(2),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      border: '2px solid #2F9D00',
      width: '100%',
      boxSizing: 'border-box',
    },
    ctaButton: {
      borderColor: '#FF4500',
      color: '#2F9D00', // Green text color
      textShadow: '1px 1px rgba(0, 0, 0, 0.5)',
      '&:hover': {
        color: '#2F9D00',
      },
      width: isMobile ? '100%' : 'auto',
      marginBottom: isMobile ? theme.spacing(2) : 0,
    },
    secondaryButton: {
      borderColor: '#FF4500',
      color: '#FF4500',
      textShadow: '1px 1px rgba(0, 0, 0, 0.5)',
      '&:hover': {
        backgroundColor: '#FF6A33',
        color: '#FFFFFF',
      },
      width: isMobile ? '100%' : 'auto',
    },
    pepeImageContainer: {
      position: 'relative',
      width: '100%',
      maxWidth: 500,
      margin: '0 auto',
    },
    pepeImage: {
      width: '100%',
      height: 'auto',
    },
    memeExampleImage: {
      width: '100%',
      height: 'auto',
      borderRadius: '8px',
      marginBottom: theme.spacing(2),
    },
    dashboardImage: {
      display: 'block',
      margin: '0 auto',
      maxWidth: '100%',
      height: 'auto',
      borderRadius: '8px',
    },
  };

  return (
    <Box sx={styles.heroSection}>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center" direction={isMobile ? 'column-reverse' : 'row'}>
          <Grid item xs={12} md={6}>
            <Typography variant="h1" gutterBottom sx={styles.pepeGreenText}>
              PepeAI
            </Typography>
            <Typography variant="h2" gutterBottom sx={styles.gradientText}>
              Overhyped "AI" CS Memes
            </Typography>
            <Typography variant="h5" paragraph sx={styles.subText}>
              Transform your images into below average computer science memes with our "AI"-powered meme generator!
            </Typography>
            <Box sx={styles.freeTrialBox}>
              <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#2F9D00', textShadow: '1px 1px #000', fontSize: isMobile ? '1.5rem' : '2rem', justifyContent: 'center' }}>
                <Sparkles size={24} color="#2F9D00" />
                New Users Get 3 Free CS Memes!
              </Typography>
              <Typography variant="body1" sx={{ textShadow: '1px 1px rgba(0, 0, 0, 0.5)', textAlign: 'center' }}>
                Sign up now and experience PepeAI for FREE!
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2, justifyContent: 'center' }}>
              <Button
                variant="outlined"
                size="large"
                component={RouterLink}
                to="/login"
                endIcon={<ArrowRight />}
                sx={styles.ctaButton}
              >
                Create Your First Meme
              </Button>
              <Button
                variant="outlined"
                size="large"
                component={RouterLink}
                to="/features"
                sx={styles.secondaryButton}
              >
                Discover PepeAI
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={styles.pepeImageContainer}>
              <img
                src="/pepe_1.png"
                alt="PepeAI Meme Preview"
                style={styles.pepeImage}
              />
            </Box>
          </Grid>
        </Grid>
        
        {/* Meme Examples Section */}
        <Box sx={{ mt: 8 }}>
          <Typography variant="h2" gutterBottom sx={{...styles.pepeGreenText, mb: 4}}>
            Meme Examples:
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <img
                src="/memez_ai.png"
                alt="Meme Example 1"
                style={styles.memeExampleImage}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <img
                src="/memez_ai2.png"
                alt="Meme Example 2"
                style={styles.memeExampleImage}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Dashboard Section */}
        <Box sx={{ mt: 8, textAlign: 'center' }}>
          <Typography variant="h2" gutterBottom sx={{...styles.pepeGreenText, mb: 4}}>
            Dashboard:
          </Typography>
          <img
            src="/dashboard.png"
            alt="Dashboard"
            style={styles.dashboardImage}
          />
        </Box>
      </Container>
    </Box>
  );
}

export default Home;