import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, useTheme, useMediaQuery, IconButton, Menu, MenuItem } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import MenuIcon from '@mui/icons-material/Menu';

function Header() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonStyle = {
    fontSize: '1rem', 
    fontWeight: 'bold', 
    color: theme.palette.secondary.main,
    textShadow: '1px 1px #000',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main + '1A', // 10% opacity
    },
  };

  const mobileButtonStyle = {
    ...buttonStyle,
    width: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left',
  };

  return (
    <AppBar position="static" sx={{ 
      backgroundColor: theme.palette.background.paper + 'CC', // 80% opacity
      backdropFilter: 'blur(8px)',
      boxShadow: theme.shadows[4],
    }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography 
          variant="h4" 
          component="div" 
          sx={{ 
            fontWeight: 'bold', 
            color: theme.palette.secondary.main,
            textShadow: '2px 2px #000',
            fontFamily: theme.typography.h1.fontFamily,
          }}
        >
          <RouterLink to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
            PepeAI
          </RouterLink>
        </Typography>
        {isMobile ? (
          <Box>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} component={RouterLink} to="/features" sx={mobileButtonStyle}>
                Features
              </MenuItem>
              <MenuItem onClick={handleClose} component={RouterLink} to="/pricing" sx={mobileButtonStyle}>
                Pricing
              </MenuItem>
              <MenuItem onClick={handleClose} component={RouterLink} to="/dashboard" sx={mobileButtonStyle}>
                Dashboard
              </MenuItem>
              {auth.currentUser ? (
                <MenuItem onClick={() => { handleClose(); handleLogout(); }} sx={mobileButtonStyle}>
                  Logout
                </MenuItem>
              ) : (
                <MenuItem onClick={handleClose} component={RouterLink} to="/login" sx={mobileButtonStyle}>
                  Login
                </MenuItem>
              )}
            </Menu>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', '& > *': { ml: 2 } }}>
            <Button 
              color="inherit" 
              component={RouterLink} 
              to="/features"
              sx={buttonStyle}
            >
              Features
            </Button>
            <Button 
              color="inherit" 
              component={RouterLink} 
              to="/pricing"
              sx={buttonStyle}
            >
              Pricing
            </Button>
            <Button 
              color="inherit" 
              component={RouterLink} 
              to="/dashboard"
              sx={buttonStyle}
            >
              Dashboard
            </Button>
            {auth.currentUser ? (
              <Button 
                variant="contained" 
                onClick={handleLogout}
                sx={{ 
                  fontSize: '1rem', 
                  fontWeight: 'bold', 
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                  textShadow: '1px 1px #000',
                }}
              >
                Logout
              </Button>
            ) : (
              <Button 
                variant="contained" 
                component={RouterLink} 
                to="/login"
                sx={{ 
                  fontSize: '1rem', 
                  fontWeight: 'bold', 
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                  textShadow: '1px 1px #000',
                }}
              >
                Login
              </Button>
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;