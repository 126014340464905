// src/pages/Login.js
import React, { useEffect, useState } from 'react';
import { Button, Container, Typography, CircularProgress, Box, Paper, Grid } from '@mui/material';
import { auth } from '../firebase';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Sparkles } from 'lucide-react';

function Login() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('User is signed in:', user.uid);
        navigate('/dashboard');
      } else {
        console.log('No user is signed in.');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleGoogleSignIn = async () => {
    setLoading(true);
    setError(null);
    try {
      console.log('Starting Google Sign In process');
      const provider = new GoogleAuthProvider();
      console.log('Google Auth Provider created');
      const result = await signInWithPopup(auth, provider);
      console.log('Sign In Successful', result.user.uid);
    } catch (error) {
      console.error('Detailed Error:', error);
      console.error('Error Code:', error.code);
      console.error('Error Message:', error.message);
      let errorMessage = 'Failed to sign in with Google. Please try again.';
      if (error.code === 'auth/api-key-not-valid') {
        errorMessage = 'Invalid API key. Please check your Firebase configuration.';
      } else if (error.code === 'auth/network-request-failed') {
        errorMessage = 'Network error. Please check your internet connection.';
      }
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: '2rem', textAlign: 'center' }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 4, backgroundColor: 'rgba(47, 157, 0, 0.1)', border: '1px solid #2F9D00' }}>
            <Typography variant="h3" gutterBottom sx={{ color: '#2F9D00', fontWeight: 'bold', textShadow: '2px 2px #000' }}>
              Welcome to PepeAI
            </Typography>
            <Typography variant="h5" gutterBottom sx={{ color: '#FF4500', textShadow: '1px 1px #000' }}>
              Where CS Memes Meet Machine Learning
            </Typography>
            <Box sx={{ marginTop: 4, marginBottom: 4 }}>
              <Typography variant="body1" paragraph>
                Join the dankest community of meme creators and AI enthusiasts!
              </Typography>
              <Typography variant="body1" paragraph>
                With PepeAI, you're just one click away from generating the spiciest CS memes on the internet.
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 3 }}>
              <Sparkles size={24} color="#FF4500" />
              <Typography variant="h6" sx={{ marginLeft: 1, color: '#FF4500', textShadow: '1px 1px #000' }}>
                New users get 3 free meme generation!
              </Typography>
            </Box>
            <Button
              variant="contained"
              onClick={handleGoogleSignIn}
              disabled={loading}
              size="large"
              sx={{ 
                backgroundColor: '#FF4500',
                '&:hover': {
                  backgroundColor: '#FF6A33',
                },
                '&:disabled': {
                  backgroundColor: 'rgba(255, 69, 0, 0.5)',
                },
                fontSize: '1.2rem',
                padding: '10px 30px',
                textShadow: '1px 1px #000'
              }}
            >
              {loading ? <CircularProgress size={24} /> : 'Sign in with Google'}
            </Button>
            {error && (
              <Typography color="error" sx={{ marginTop: 2 }}>
                {error}
              </Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ position: 'relative', width: '100%', maxWidth: 500, margin: '0 auto' }}>
            <img
              src="/pepe_3.png"
              alt="PepeAI Meme Preview"
              style={{ 
                width: '100%', 
                height: 'auto', 
                borderRadius: '10px',
                boxShadow: '0 0 20px rgba(255, 69, 0, 0.3)'
              }}
            />
            <Box sx={{ 
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 69, 0, 0.1)',
              borderRadius: '10px'
            }} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Login;